
import { Options, Vue } from 'vue-class-component'
import Header from '@/components/Header.vue' // @ is an alias to /src
import Banner from '@/components/Banner.vue' // @ is an alias to /src

@Options({
  components: {
    Header,
    Banner
  }
})
export default class App extends Vue {}
