<template>
  <div class="header-placeholder"></div>
  <div class="header">
    <div class="container">
      <router-link to="/manifesto">Manifesto</router-link>
      <router-link to="/principles">Principles</router-link>
      <!-- <router-link to="/projects">Projects</router-link> -->
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  width: 100%;
  height: 70px;

  position: absolute;
  top: 12px;
  left: 0;
}

.header-placeholder {
  width: 100%;
  height: 70px;

  background-color: #000000;
}

.container {
  max-width: 1200px;
  margin: auto;
  height: 100%;

  display: flex;
  justify-content: left;

  padding: 0 12px;
}

.container a {
  color: #bbbbbb;
  text-decoration: none;
  margin-right: 16px;

  font-size: 1.3rem;
}

.container a.router-link-exact-active {
  color: #ffffff;
  font-weight: bold;
}
</style>
