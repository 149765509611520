<template>
  <div class="home">
    <div class="about">
      <div class="section">
          <h2>Links</h2>
      </div>
      <ul>
        <li>
          <a href="https://www.linkedin.com/in/steven-s-77a43672/">Linkedin</a>
        </li>
      </ul>
    </div>
  </div>
</template>
