<template>
<Header />
<Banner />
<router-view/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Header from '@/components/Header.vue' // @ is an alias to /src
import Banner from '@/components/Banner.vue' // @ is an alias to /src

@Options({
  components: {
    Header,
    Banner
  }
})
export default class App extends Vue {}
</script>

<style>
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  padding: 0;
  margin: 0;

  font-size: 16px;

  height: 100%;
  width: 100%;
}

h2 {
  border-bottom: 4px solid #000000;
  width: auto;

  display: inline-flex;

  font-size: 56px;
  line-height: 64px;
}

h3 {
  text-align: left;
  width: auto;

  display: inline-flex;

  font-size: 40px;
  line-height: 48px;

  margin: 0;
}

h4 {
  text-align: left;

  width: auto;

  font-size: 16px;
  line-height: 24px;

  opacity: .5;
  margin: 0;
}

.section {
  max-width: 800px;
  margin: auto;

  padding-bottom: 40px;

  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
}

ul {
  list-style: none;
  text-align: left;
}
</style>
