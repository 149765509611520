<template>
  <div class="banner">
    <div class="container">
      <h1>Steven Shockley</h1>
      <picture>
        <source srcset="@/assets/shockley@x2.jpeg" media="(min-width: 1366px)">
        <source srcset="@/assets/shockley_mobile.jpeg" media="(min-width: 400px)">

        <img src="@/assets/shockley@x1.jpeg">
      </picture>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .banner {
    background: #000000;
    height: 100%;
    z-index: -1;

    padding: 0 20px;
    box-sizing: border-box;

    overflow: hidden;
  }

  h1 {
    color: #ffffff;
    font-size: 8rem;
  }

  @media only screen and (max-width: 1366px) {
    h1 {
      font-size: 6rem;
    }
  }

  .container {
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media only screen and (max-width: 800px) {
    h1 {
      font-size: 3rem;
    }
    .container {
      flex-direction: column-reverse;
    }
  }
</style>
